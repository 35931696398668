import React, { Component } from "react";
import Menu from "Partials/Menu";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import General from "Helpers/General";
import Product from "Models/Product";
import LiveStreamingList from "Components/Users/LiveStreamingList";
import AdsList from "Components/Users/AdsList";
import Moment from "react-moment";
import ReviewComponent from "./ReviewComponent";
import { LinearProgress } from "@material-ui/core";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";
import QB, { playCallAudio } from "Helpers/Quickblox";

import {
  OpenMessageBox,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetScheduleMeetingState
} from "Helpers/Actions";
import { connect } from "react-redux";

class ProductListComponent extends Component {
  constructor(props) {
    super(props);

    const userId = this.props.match.params.userId;
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      userId: userId,
      userData: {},
      products: [],
      reviews: [],
      allReviewsList: [],
      isFollower: false,
      isFollowing: false,
      totalCount: 0,
      avgRating: 0,
      allCategories: localStorage.getItem("PostCategories")
        ? JSON.parse(localStorage.getItem("PostCategories"))
        : [],
      productFilters: {
        category: "",
        sortType: "",
        min_price: 0,
        max_price: "",
        upcomingLiveAuction: false,
        liveAuction: false,
        AvailableBuyNow: false,
        AvailablePickUp: false
      },
      authUserId: Product.getAuthUserId(),
      isLoading: false,
      nothingD: false,
      showOtherFilter: false,
      reviewLength: 4,
      addPosted: 0,
    };

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  componentDidMount = () => {
    this.getAllProducts();
    this.adPostedStatus();
  };

  adPostedStatus = () => {
    Product.postApi(
      "API_GET_AD_POSTED_STATUS",
      { userId: this.state.authUserId },
      async (response, status) => {
        if (status === "success") {
          this.setState({ addPosted: 1 });
        } else {
          this.setState({ addPosted: 0 });
        }
      },
      true
    );
  }

  categoryFilter = (id = "") => {
    let { productFilters } = this.state;
    productFilters["category"] = id;
    this.setState({ productFilters: productFilters });
    let liveAuctionData = this.state.allCategories.find((cat) => {
      return cat._id === id && cat.slug === "live-auction";
    });
    if (liveAuctionData) {
      this.setState({ showOtherFilter: true });
      productFilters["AvailableBuyNow"] = false;
      productFilters["AvailablePickUp"] = false;
    } else {
      this.setState({ showOtherFilter: false });
      productFilters["liveAuction"] = false;
      productFilters["upcomingLiveAuction"] = false;
    }
    this.getAllProducts();
  };

  handlePrice = (e) => {
    let { productFilters } = this.state;
    productFilters[e.target.name] = e.target.value;
    this.setState({ productFilters: productFilters });
    // this.getAllProducts();
  };

  otherOptionHandler = (e) => {
    const { productFilters } = this.state;
    if (e.target.checked) {
      productFilters[e.target.name] = true;
      this.setState(productFilters);
    } else {
      productFilters[e.target.name] = false;
      this.setState(productFilters);
    }
    this.getAllProducts();
  };

  filterSortingHandler = (e) => {
    let { productFilters } = this.state;
    productFilters["sortType"] = e.target.value;
    this.setState({ productFilters: productFilters });
    this.getAllProducts();
  };

  followHandler = (id) => {
    // e.preventDefault();
    this.setState({ isLoading: true });
    Product.postApi(
      "APIS_FOLLOW_USER",
      { id },
      async (response) => {
        this.getAllProducts();
        this.setState({
          isLoading: false,
        });
      },
      true
    );
  };

  unfollowHandler = (id) => {
    this.setState({ isLoading: true });
    Product.postApi(
      "APIS_UNFOLLOW_USER",
      { id },
      async (response) => {
        this.getAllProducts();
        this.setState({
          isLoading: false,
        });
      },
      true
    );
  };

  getAllProducts = () => {
    const { userId, productFilters, authUserId } =
      this.state;
    this.setState({ isLoading: true });
    Product.postApi(
      "APIS_GET_ALL_PRODUCTS_BY_USER_ID",
      { userId, productFilters, authUserId },
      async (response) => {
        console.log("response", response);
        this.setState({
          userData: response.user,
          products: response.products,
          reviews: response.reviews,
          allReviewsList: response.reviews,
          avgRating: response.avgRating,
          totalCount: response.totalCount,
          isFollowing: response.isFollowing,
          isFollower: response.isFollower,
          isLoading: false,
          nothingD: true,
        });
      }
    );
  };

  // VideoCall & Message Code::Start

  sendMessageHandler = () => {
    if (localStorage.getItem("AuthIdentity")) {
      this.props.OpenMessageBox(true);
      localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
      localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
    }
  };

  startVideoCall = (email) => {
    if (email) {
      QB.users.get({ login: email }, (error, user) => {
        if (!error) {
          this.initiateCall(user.id);
        } else {
          console.log("Couldn't find user");
        }
      });
    }
  };

  initiateCall = (userId) => {
    this.props.SetOutgoingCallState(true);
    var calleesIds = [userId]; // Users' ids
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );
    // console.log(session);
    var mediaParams = {
      audio: true,
      video: true,
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideoElem",
    };
    this.props.SetQuickbloxSession(session);
    session.stop({});
    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        this.props.SetOutgoingCallState(false);
        this.props.SetQuickbloxSession(null);
        session.stop({});
        console.log("Get user stream error => ", error);
      } else {
        //run call function here
        // console.log(stream);
        // session.attachMediaStream("localVideoElem", stream);
        if (stream) {
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name }, (error1) => {
            playCallAudio();
            console.log(error1);
          });
        }
      }
    });
  };
  // VideoCall & Message Code::End

  filterWithRating = (e) => {
    if (e > 0) {
      let allReviews = this.state.reviews.filter((review) => review.rating === e);
      this.setState({ allReviewsList: allReviews });
    } else {
      let allReviews = this.state.reviews;
      this.setState({ allReviewsList: allReviews });
    }
    this.setState({ reviewLength: 4 });
  }

  seeAllReviews = (e) => {
    e.preventDefault();
    let allReviews = this.state.allReviewsList;
    this.setState({ reviewLength: allReviews.length });
  }
  handleSelectedProfile = (email) => {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_ApiBaseUrl + `getSelectedUser`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(async (response) => {
          if (response && response !== undefined) {
            let data = await response.json();
            if (data && data.length) {
              this.setState({ selectedUserDetails: data });
              resolve({ 'status': true });
              // this.getScheduledEvents();
            } else {
              resolve({ 'status': false });
            }
          } else {
            resolve({ 'status': false });
          }
        }).catch((err) => {
          console.log(err)
          resolve({ 'status': false });
        })
    })

  };
  scheduleCall = async (email) => {
    let response = await this.handleSelectedProfile(email);
    if (response.status) {
      this.props.SetScheduleMeetingState({ state: true, toQbUserId: this.state.userid, toUserId: this.state.selectedUserDetails[0]._id, username: this.state.selectedUserDetails[0].firstName + " " + this.state.selectedUserDetails[0].lastName });
    }
  };


  render() {
    const {
      userId,
      userData,
      products,
      reviews,
      allReviewsList,
      reviewLength,
      avgRating,
      totalCount,
      allCategories,
      productFilters,
      isFollowing,
      isLoading,
    } = this.state;
    console.log("userData", userData);
    return (
      <React.Fragment>
        {isLoading && (
          <div class="loader-wrapper" id="loader_img">
            <div class="loader">
              <img src={this.state.headerLogo} alt="" />
              <div class="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="video-chat-header">
          <div className="container">
            <a href="#" className="video-chat-btn">
              <span>
                <img src="/images/video-chat-icon.png" alt="icon" />
              </span>{" "}
              Open Video Chat
            </a>
            <a href="#" className="number-reveal-btn ml-2">
              <img src="/images/phone-icon.png" alt="icon" />{" "}
              <span>Reveal Phone Number</span>
            </a>
          </div>
        </section>

        <section className="repeat-section product-detail-main-bx bg-light-dark allproduct_topmain">
          <div className="container">
            <div className="row">
              <div className="col-xl-12  col-lg-12">
                <div className="top_rated_sec onlypage06 onlyfor06_block">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="detail-owner-name-bx clearfix">
                        <div className="detail-owner-img">
                          {userData?.profilePicture ? (
                            <img
                              src={General.showImageNew(
                                "profile",
                                userData.profilePicture
                              )}
                              onError={
                                (e) => e.target.src = '/images/user.png'
                              }
                              alt={userData?.profilePicture}
                            />
                          ) : (
                            <img src="/images/user.png" alt="image1" />
                          )}
                          <span
                            className="live-dot"
                            style={{
                              background: `${userData?.isOnline && userData?.goesOffline === 1 ? "#249ee4" : "rgb(203, 211, 227)"
                                }`
                            }}
                          ></span>
                        </div>
                        <div className="detail-owner-name">
                          <div className="rated-star">
                            <span>Rated</span>{" "}
                            <span>
                              <strong>
                                {avgRating ? avgRating.toFixed(1) : 0.0}{" "}
                              </strong>
                            </span>
                            <StarRatings
                              rating={avgRating ? avgRating : 0}
                              starRatedColor="#249ee4"
                              starDimension="20px"
                              starSpacing="0"
                              numberOfStars={5}
                            />
                          </div>
                          <h2 className="font-size-16">
                            {userData && userData.firstName}{" "}
                            {userData && userData.lastName}
                          </h2>
                          <div className="rated-star">{userData && userData.companyName}</div>
                          <div className="rated-star">
                            {/* @{userData && userData.username} */}
                          </div>
                          {userData && Product.getAuthUserId() && userId !== Product.getAuthUserId() && (
                            <div class="usednew_button position-relative pl-3 mt-2 usednew_button_mobile">
                              {isFollowing ? (
                                <Link
                                  class="btnnew_used"
                                  onClick={() =>
                                    this.unfollowHandler(userData?._id)
                                  }
                                >
                                  Unfollow
                                </Link>
                              ) : (
                                <Link
                                  class="btnnew_used"
                                  onClick={() =>
                                    this.followHandler(userData?._id)
                                  }
                                >
                                  Follow
                                </Link>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="Jvideo_cus_perent">
                        <div className="rated-customer-bx">
                          <div className="rated-customer-bx-inner rated-customer-bx-inner-mobile ">
                            Member Since{" "}
                            <Moment format="MMM YYYY">{userData?.date}</Moment>
                            <h2>{totalCount} Listed Items</h2>
                            {userData?.isOnline &&
                              userData?.goesOffline === 1 ? (
                              <span className="online_status">Online</span>
                            ) : (
                              <>
                                <span class="or_text">Last Seen:</span>{" "}
                                <Moment fromNow>
                                  {userData?.lastLogin}
                                </Moment>
                              </>
                            )}
                          </div>
                        </div>
                        {(userData && userData?._id && JSON.parse(localStorage.getItem("UserData")) &&
                          userData._id !==
                          JSON.parse(localStorage.getItem("UserData"))
                            ._id) && (
                            <div className="Jvideochat_cus  videochat-btn-group ">
                              {
                                totalCount > 0 && (
                                  <a
                                    href
                                    className="number-reveal-btn"
                                    onClick={this.sendMessageHandler}
                                  >
                                    <img src="/images/msg-icon.png" alt="icon" />{" "}
                                    <span>Message</span>
                                  </a>
                                )}
                              {
                                totalCount > 0 && (
                                  <>
                                    {userData?.isOnline ? (
                                      <a
                                        href
                                        className="video-chat-btn scheduleCallBtn"
                                        onClick={() =>
                                          this.startVideoCall(userData?.email)
                                        }
                                      >
                                        <span>
                                          <img
                                            src="/images/video-chat-icon.png"
                                            alt="icon"
                                          />
                                        </span>{" "}
                                        Open Video Chat
                                      </a>
                                    ) : (
                                      <a
                                        href
                                        className="video-chat-btn scheduleCallBtn"
                                        onClick={() =>
                                          this.scheduleCall(userData?.email)
                                        }
                                      >
                                        <span>
                                          <img
                                            src="/images/video-chat-icon.png"
                                            alt="icon"
                                          />
                                        </span>{" "}
                                        Schedule Video Chat
                                      </a>
                                    )
                                    }
                                  </>
                                )
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-all-detail-tab product-all-detail-tab_box">
              <ul
                className="nav nav-tabs my-custom-tab cuttan_newimg specialshap_likedetailP  custome-tabs-scroll"
                role="tablist"
              >
                <li className="nav-item specialshap_item">
                  <a
                    className={"nav-link " + ((this.props.location?.query?.reviews && this.props.location.query.reviews) ? '' : 'active')}
                    //className="nav-link active"
                    data-toggle="tab"
                    href="#Jcomp_allprod"
                    role="tab"
                  >
                    <span>Items </span>
                    {/* <img src="/images/tabicon01.png" alt="Icon" /> */}
                  </a>
                </li>
                {/*  <li className="nav-item specialshap_item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Jcompany_activity"
                    role="tab"
                  >
                    <span>Auction Show</span>
                    <img src="/images/tabicon02.png" alt="Icon" />
                  </a>
                </li> */}
                <li className="nav-item specialshap_item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#company-profile"
                    role="tab"
                  >
                    <span>About</span>
                    {/* <img src="/images/tabicon03.png" alt="Icon" /> */}
                  </a>
                </li>
                <li className="nav-item specialshap_item">
                  <a
                    className={"nav-link " + ((this.props.location?.query?.reviews && this.props.location.query.reviews) ? 'active' : '')}
                    data-toggle="tab"
                    href="#customers-reviews"
                    role="tab"
                  >
                    <span>Customers Reviews</span>
                    {/* <img src="/images/tabicon04.png" alt="Icon" /> */}
                  </a>
                </li>
              </ul>
              <div className="tab-content allproduct_pagemainT">
                <AdsList
                  nothingD={this.state.nothingD}
                  products={products}
                  categories={allCategories}
                  selectedCategory={productFilters["category"]}
                  getAllProducts={this.getAllProducts}
                  categoryFilter={this.categoryFilter}
                  handlePrice={this.handlePrice}
                  otherOptionHandler={this.otherOptionHandler}
                  filterSortingHandler={this.filterSortingHandler}
                  showOtherFilter={this.state.showOtherFilter}
                  showSection={((this.props.location?.query?.reviews && this.props.location.query.reviews) ? false : true)}
                />
                <div
                  className="tab-pane tabacountsetig_target"
                  id="company-profile"
                  role="tabpanel"
                >
                  <div className="product-detail-tab">
                    <h2 className="same-bx-heading about_heading">About Us</h2>
                    <p className="product-specs">
                      {userData && userData?.about
                        ? userData.about
                        : "N/A"}
                    </p>
                    <div className="row border-top pt-3">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-6">
                            <p className="product-specs">Seller Type:</p>
                          </div>
                          <div className="col-6">
                            <p className="product-specs">
                              {userData && userData.sellerType == "1" ? (
                                "Company"
                              ) : userData && userData.sellerType == "2" ? (
                                "Soletrader"
                              ) : userData && userData.sellerType == "3" ? (
                                "Private"
                              ) : (
                                "N/A"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="row">
                          <div className="col-6">
                            <p className="product-specs">Company:</p>
                          </div>
                          <div className="col-6">
                            <p className="product-specs bold-text">
                              {userData && userData?.companyName
                                ? userData.companyName
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-6">
                            <p className="product-specs">Location:</p>
                          </div>
                          <div className="col-6">
                            <p className="product-specs bold-text">
                              {userData && userData?.location
                                ? userData.location
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane subtab_prodlist_NEW_forpadd tabcustomer_target p-0 pb-4 pb-md-0 " + ((this.props.location?.query?.reviews && this.props.location.query.reviews) ? 'active' : '')}
                  id="customers-reviews"
                  role="tabpanel"
                >
                  <div className="row align-items-en">
                    <div className="col-xl-12 customer-review-bx d-flex flex-wrap justify-content-between">
                      <h2>{reviews?.length} Reviews from Customers</h2>
                      <div className="rating_box pb-2">
                        <div className="star star-filter" onClick={() => this.filterWithRating(0)}>
                          <span>
                            (All)
                          </span>
                        </div>
                        <div className="star star-filter col d-content" onClick={() => this.filterWithRating(5)}>
                          <span>
                            (5)
                          </span>
                          <StarRatings
                            rating={5}
                            starRatedColor="#249ee4"
                            starDimension="17px"
                            starSpacing="1px"
                            numberOfStars={5}
                          />
                          <div className="rating-row row align-items-center mobile_view">
                          <div className="star-txt">5 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 5)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 5)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 5)})</span>
                          </div>
                          </div>
                        </div>
                        <div className="star star-filter col d-content" onClick={() => this.filterWithRating(4)}>
                          <span>
                            (4)
                          </span>
                          <StarRatings
                            rating={4}
                            starRatedColor="#249ee4"
                            starDimension="17px"
                            starSpacing="1px"
                            numberOfStars={5}
                          />
                          <div className="rating-row row align-items-center mobile_view">
                          <div className="star-txt">4 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 4)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 4)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 4)})</span>
                          </div>
                        </div>
                        </div>
                        <div className="star star-filter col d-content" onClick={() => this.filterWithRating(3)}>
                          <span>
                            (3)
                          </span>
                          <StarRatings
                            rating={3}
                            starRatedColor="#249ee4"
                            starDimension="17px"
                            starSpacing="1px"
                            numberOfStars={5}
                          />
                          <div className="rating-row row align-items-center mobile_view">
                          <div className="star-txt">3 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 3)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 3)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 3)})</span>
                          </div>
                        </div>
                        </div>
                        <div className="star star-filter col d-content" onClick={() => this.filterWithRating(2)}>
                          <span>
                            (2)
                          </span>
                          <StarRatings
                            rating={2}
                            starRatedColor="#249ee4"
                            starDimension="17px"
                            starSpacing="1px"
                            numberOfStars={5}
                          />
                          <div className="rating-row row align-items-center mobile_view">
                          <div className="star-txt">2 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 2)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 2)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 2)})</span>
                          </div>
                        </div>
                        </div>
                        <div className="star star-filter col d-content" onClick={() => this.filterWithRating(1)}>
                          <span>
                            (1)
                          </span>
                          <StarRatings
                            rating={1}
                            starRatedColor="#249ee4"
                            starDimension="17px"
                            starSpacing="1px"
                            numberOfStars={5}
                          />
                          <div className="rating-row row align-items-center mobile_view">
                          <div className="star-txt">1 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 1)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 1)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 1)})</span>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="customer-review-bx desktop_view">
                        <div className="rating-row row align-items-center">
                          <div className="star-txt">5 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 5)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 5)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 5)})</span>
                          </div>
                        </div>
                        <div className="rating-row row align-items-center">
                          <div className="star-txt">4 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 4)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 4)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 4)})</span>
                          </div>
                        </div>
                        <div className="rating-row row align-items-center">
                          <div className="star-txt">3 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 3)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 3)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 3)})</span>
                          </div>
                        </div>
                        <div className="rating-row row align-items-center">
                          <div className="star-txt">2 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 2)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 2)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 2)})</span>
                          </div>
                        </div>
                        <div className="rating-row row align-items-center">
                          <div className="star-txt">1 Stars</div>
                          <div className="rating-view col">
                            <LinearProgress
                              variant="determinate"
                              value={General.showRatingPercentage(reviews, 1)}
                            />
                          </div>
                          <div className="rating-text col">
                            <h4>{General.showRatingPercentage(reviews, 1)}%</h4>
                          </div>
                          <div className="star col">
                            <span>({General.showRatingCount(reviews, 1)})</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" customer-review-profile_block col-xl-8">
                      <div class="row pb-3">
                        <div class="col-md-3">
                                <span>   <b>{allReviewsList?.length} </b> reviews</span>
                        </div>
                        <div class="col-md-3"><span>Share your reviews</span></div>
                        <div class="col-md-3"><span>Share</span></div>
                        <div class="col-md-3">
                          <div class="rated-customer-bx-inner">
                            <div class="rated-star text-left">
                              <span>Rated</span>
                              <StarRatings
                                rating={this.state.avgRating}
                                starRatedColor="#249ee4"
                                starDimension="17px"
                                starSpacing="1px"
                                numberOfStars={5}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {allReviewsList &&
                        allReviewsList.slice(0, reviewLength).map((review) => (
                          <ReviewComponent review={review} key={review._id} />
                        ))}
                      {
                        (allReviewsList.length > allReviewsList.slice(0, reviewLength).length) && <div className="text-right">
                          <Link
                            to="#"
                            className="see-all-btn"
                            onClick={this.seeAllReviews}
                          >
                            See All
                          </Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="side-bar-stoper"></div>
            </div>
          </div>
        </section>

        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    scheduleMeetingState: state.VIDEO_CALL.scheduleMeetingState
  };
};
export default connect(mapStateToProps, {
  OpenMessageBox,
  SetOutgoingCallState,
  SetScheduleMeetingState,
  SetQuickbloxSession,
})(ProductListComponent);
