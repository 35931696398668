import React, { Component } from "react";
import { Link } from "react-router-dom";
import Wishlist from "./Wishlist";
import RecentViewed from "./RecentViewed";
import ManageList from "./ManageList";
import AuctionShow from "./AuctionShow";
var qs = require("qs");


export class Activity extends Component {

    constructor(props) {

        super(props);
        let session_id = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        }).session_id;

        let session_resp = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        }).response;
        window.scrollTo(0, 0);


        this.state = {
            session_id: session_id ? session_id : "",
            session_resp: session_resp ? session_resp : "",
            userId: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["_id"]
                : "",
            tabView: 'manage_list'
        }

    }
    componentDidMount = () => {
        if (this.state.session_resp) {
            const currentUrl = window.location.href;
            const newUrl = currentUrl.split("?")[0];
            window.history.replaceState({ path: newUrl }, "", newUrl);
        }
    };

    handleTabes = (tab) => {
        this.setState({
            tabView: tab
        });
    }

    render() {
        const { tabView } = this.state;
        return (
            <React.Fragment>
                <div
                    id="myownlevel"
                    role="tabpanel"
                >
                    <div className="product-all-detail-tab">
                        <ul
                            className="nav nav-tabs my-custom-tab  specialshap_likedetailP my-custom-tab-mobile"
                            /* className="nav nav-tabs fororange_colorbg new_ctab_inntab specialshap_likedetailP_innerT cuttan_newimg" */
                            role="tablist"
                        >
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link active in"
                                    data-toggle="tab"
                                    href={void (0)}
                                    onClick={() => this.handleTabes('manage_list')}
                                    role="tab"
                                >
                                    <span> Manage Listings </span>
                                </a>
                            </li>
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    onClick={() => this.handleTabes('auction_show')}
                                    role="tab"
                                >
                                    {/* <img src="/images/act07.png" alt="icon" />{" "} */}
                                    <span> Manage Auction</span>
                                </a>
                            </li>
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('wishlist')}
                                >
                                    <span> Wishlist </span>
                                </a>
                            </li>
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('recent_viewed')}
                                >
                                    <span> Recently Viewed </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content subtab_tabcont">
                        {(tabView === 'manage_list') ?
                            <ManageList />
                            : (tabView === 'auction_show') ?
                                <AuctionShow />
                                : (tabView === 'wishlist') ?
                                    <Wishlist />
                                    : <RecentViewed />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Activity;
