import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Product from "Models/Product";

export class ScheduleProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
    };
  }

  handleEditProdcutChange = (e) => {

    const { name, value } = e.target;
    let { product } = this.props;

    product[name] = value;
    this.setState({ product });

    if (parseInt(value) >= 0 && value !== '') {
      document.getElementById(name + "_error_" + this.props.index).style.display = "none";
    }

    if (name == "selling_estimate") {
      if (parseInt(value) >= 0 && value !== '') {
        document.getElementById(name + "_price_error_" + this.props.index).style.display = "none";
      }
    }
  }
  onNumberInputKeyDown = (event) => {
    if (event.key === "e" || event.key === "-" || event.keyCode === 189 || event.which === 189) event.preventDefault();
  }

  /* submitEditProductForm = (e) => {
    e.preventDefault();
    let error = 0;
    const { starting_price, selling_estimate } = this.state;
   
    if(parseInt(starting_price) <= 0 || starting_price === ''){
      document.getElementById("starting_price_error").style.display = "block";
      error++;
    }

    if(parseInt(selling_estimate) <= 0 || selling_estimate === ''){
      document.getElementById("selling_estimate_error").style.display = "block";
      error++;
    }

    if(error > 0){
      return;
    }else{
      Product.postApi(
        "APIS_SUBMIT_AUCTION_PRICES",
        {
          productId: this.props.product._id,
          starting_price: starting_price,
          selling_estimate: selling_estimate
        },
        async (response,status) => {
          if (status === "success") {
            let { product } = this.props;
            product["starting_price"] = response.starting_price;
            product["selling_estimate"] = response.selling_estimate;
            this.setState({ product: product });
            this.setState({ editModal: false });
            document.getElementById(product._id).style = "border: none;";
            document.getElementById(product._id+"_error").style.display = "none";
          }
        },
        true 
      );
    }
  } */

  render() {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    const { product } = this.props;

    return (

      <>
        <div className="col-lg-12 listside_newperent desktop_view" id={product._id}>
          <div className="product-listing-product">
            <div className="row">
              <div className="col-3">
                <div className="product-img-bx">
                  <Link to={`/product/${product.productSlug}`}>
                    {product.adType && (
                      <div className="specialtags">
                        <span
                          className={
                            (product.adType === "Featured"
                              ? "black"
                              : product.adType === "Urgent"
                                ? "red"
                                : "green") + "_special"
                          }
                        >
                          {product.adType}{" "}
                        </span>
                      </div>
                    )}
                    {product.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          product.productMainImage
                        )}
                        alt={product.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {product.productImages ? product.productImages.length : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-9">
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="row">
                      <div className="col-md-5">
                        {/* <Moment fromNow>{product.time}</Moment> */}
                        <Link className="live-productName" to={`/product/${product.productSlug}`}>
                          <h4>{product.productName}</h4>
                        </Link>
                        <h5>{product.location && product.location}</h5>
                        <h6>
                          <span>{General.showPriceDecimal(product.productPrice)}</span>
                        </h6>
                        <div>
                          <p><b>Auction Length : </b>{this.props.scheduleLength} Min</p>
                        </div>
                        <ul className="listofsubtabbx">
                          <li>
                            <strong>Added</strong>
                            <span>
                              <Moment format="YYYY/MM/DD">{product.time}</Moment>
                            </span>
                          </li>
                          {product.planId && (
                            <li>
                              <strong>Expires</strong>
                              <span>
                                <Moment
                                  add={{
                                    days: product.planId.duration,
                                  }}
                                  format="YYYY/MM/DD"
                                >
                                  {product.time}
                                </Moment>
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="col-md-7">
                        <div className="subtab-edit-inner subtab_edit">
                          {/* <div className="subtab_editlabl">
                      <a
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Edit"
                        onClick={() => this.setState({editModal:true}) }
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </a>
                    </div> */}
                          {/* <p className="subtab_editlabl">
                      <span className="labltable lablesct">
                        {product.active === true ? "Active" : "Inactive"}
                      </span>
                    </p> */}
                          <div className="productSubSec">
                            <form className="form-horizontal" /* onSubmit={this.submitEditProductForm} */>
                              <div className="form-group row">
                                <div className="col-12">
                                  <div className="group-input">
                                    <input className="form-control" type="number" min="0" name="starting_price" value={(product.starting_price) ? product.starting_price : ""} onChange={this.handleEditProdcutChange}
                                      onKeyDown={this.onNumberInputKeyDown}
                                    />
                                    <div className="control-label">
                                      Starting Price :
                                    </div>
                                  </div>
                                  <p id={`starting_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Starting price is required*</p>
                                  <p id={`starting_max_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Starting Price must be less than 100,000*</p>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-12">
                                  <div className="group-input">
                                    <input className="form-control" type="number" min="0" name="selling_estimate" value={(product.selling_estimate) ? product.selling_estimate : ""} onChange={this.handleEditProdcutChange}
                                      onKeyDown={this.onNumberInputKeyDown}
                                    />
                                    <div className="control-label">
                                      Selling Estimate :
                                    </div>
                                  </div>
                                  <p id={`selling_estimate_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Selling estimate is required*</p>
                                  <p id={`selling_estimate_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Selling estimate should not be less then starting price*</p>
                                  <p id={`selling_max_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Selling estimate must be less than 100,000*</p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 listside_newperent mobile_view" id={product._id}>
          <div className="product-listing-product">

            <div className="extra_card_box">
              <div className="product_listing_product_block">
                <div className="product-img-bx">
                  <Link to={`/product/${product.productSlug}`}>
                    {product.adType && (
                      <div className="specialtags">
                        <span
                          className={
                            (product.adType === "Featured"
                              ? "black"
                              : product.adType === "Urgent"
                                ? "red"
                                : "green") + "_special"
                          }
                        >
                          {product.adType}{" "}
                        </span>
                      </div>
                    )}
                    {product.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          product.productMainImage
                        )}
                        alt={product.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {product.productImages ? product.productImages.length : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>

                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="row">
                      <div className="col-md-5">
                        <Moment fromNow>{product.time}</Moment>
                        <Link to={`/product/${product.productSlug}`}>
                          <h4>{product.productName}</h4>
                        </Link>
                        <h5>{product.location && product.location}</h5>
                        <h6>
                          <span>{General.showPriceDecimal(product.productPrice)}</span>
                        </h6>
                        <div>
                          <p><b>Auction Length : </b>{this.props.scheduleLength} Min</p>
                        </div>
                        <ul className="listofsubtabbx">
                          <li>
                            <strong>Added</strong>
                            <span>
                              <Moment format="YYYY/MM/DD">{product.time}</Moment>
                            </span>
                          </li>
                          {product.planId && (
                            <li>
                              <strong>Expires</strong>
                              <span>
                                <Moment
                                  add={{
                                    days: product.planId.duration,
                                  }}
                                  format="YYYY/MM/DD"
                                >
                                  {product.time}
                                </Moment>
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="subtab-edit-inner subtab_edit">
                {/* <div className="subtab_editlabl">
              <a
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Edit"
                onClick={() => this.setState({editModal:true}) }
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
            </div> */}
                {/* <p className="subtab_editlabl">
              <span className="labltable lablesct">
                {product.active === true ? "Active" : "Inactive"}
              </span>
            </p> */}
                <div className="productSubSec">
                  <form className="form-horizontal form_horizontal_mobile" /* onSubmit={this.submitEditProductForm} */>
                    <div className="form-group">

                      <div className="">
                        <div className="group-input">
                          <input className="form-control" type="number" min="0" name="starting_price" value={(product.starting_price) ? product.starting_price : ""} onChange={this.handleEditProdcutChange}
                            onKeyDown={this.onNumberInputKeyDown}

                          />
                          <div className="control-label">
                            Starting Price :
                          </div>
                        </div>
                        <p id={`starting_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Starting price is required*</p>
                      </div>
                    </div>
                    <div className="form-group">

                      <div className="">
                        <div className="group-input">
                          <input className="form-control" type="number" min="0" name="selling_estimate" value={(product.selling_estimate) ? product.selling_estimate : ""} onChange={this.handleEditProdcutChange}
                            onKeyDown={this.onNumberInputKeyDown}

                          />
                          <div className="control-label">
                            Selling Estimate :
                          </div>
                        </div>
                        <p id={`selling_estimate_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Selling estimate is required*</p>
                        <p id={`selling_estimate_price_error_${this.props.index}`} className="text-danger" style={{ display: "none" }}>Selling estimate should not be less then starting price*</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ScheduleProductList;
