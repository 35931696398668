import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import CheckoutModel from "Models/CheckoutModel";
import Storage from "Helpers/Storage";
import Modal from "react-modal";

export class Postage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      homeDetail: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["homeDetail"]
        : {},
      postageDetail: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["postageDetail"]
        : {},
      showHomeAddressModal: false,
      showPostageAddressModal: false,
      countries: [],
      regions: [],
      cities: [],
      countriesHome: [],
      regionsHome: [],
      citiesHome: [],
      isPostageDetail: false,
      isHomeDetail: false,
      isLoading: false,
      firstName: "",
      lastName: "",
    };
  }

  componentDidMount = () => {
    this.getUserByEmail();
    this.getAllCountries();
    console.log("homedetail", this.state);
  };

  handleMyProfileContactDetails = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmitChangeDeliveryAddress = async (event) => {
    event.preventDefault();
    const { postageDetail, isPostageDetail } = this.state;
    const { firstName, lastName } = this.state;
    if (
      (await CheckoutModel.checkValidation(
        await CheckoutModel.validationRules(),
        postageDetail
      )) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          firstName,
          lastName,
          postageDetail,
          isPostageDetail: true,
        },
        async (response, err) => {
          if (response) {
            this.getUserByEmail();
            this.setState({
              isLoading: false,
              showPostageAddressModal: false,
              isPostageDetail: true,
            });
          }
        },
        true
      );
    }
  };

  handleSubmitChangeHomeAddress = async (event) => {
    event.preventDefault();
    const { homeDetail } = this.state;
    if (
      (await CheckoutModel.checkValidation(
        await CheckoutModel.validationRules(),
        homeDetail
      )) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          homeDetail,
          isHomeDetail: true,
        },
        async (response, err) => {
          if (response) {
            this.getUserByEmail();
            this.setState({
              isLoading: false,
              showHomeAddressModal: false,
              isHomeDetail: true,
            });
          }
        },
        true
      );
    }
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        console.log("response", response);
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            id: response._id,
            postageDetail: response.postageDetail ? response.postageDetail : {},
            homeDetail: response.homeDetail ? response.homeDetail : {},
            isPostageDetail: response.isPostageDetail,
            isHomeDetail: response.isHomeDetail,
            firstName: response.firstName,
            lastName: response.lastName,
          });
        }
      },
      true
    );
  };

  handlePostageDetails = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail);
  };

  handleHomeDetails = (event) => {
    const { homeDetail } = this.state;
    homeDetail[event.target.name] = event.target.value;
    this.setState(homeDetail);
  };

  getAllCountries = () => {
    MyProfile.postApiByParams(
      "APIS_GET_COUNTRIES",
      {},
      async (response, status) => {
        if (status === "success") {
          this.setState({
            countries: response.data,
            countriesHome: response.data,
          });
        }

        this.getAllRegions();
        this.getAllCities();
        this.getAllHomeRegions();
        this.getAllHomeCities();
      },
      true
    );
  };

  getAllRegions = () => {
    let country_id = this.state.postageDetail.country_id
      ? this.state.postageDetail.country_id
      : "";
    if (country_id != undefined && country_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_STATE",
        { country_id: country_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              regions: response.data,
            });
          }
        },
        true
      );
    }
  };

  getAllCities = () => {
    let state_id = this.state.postageDetail.region_id
      ? this.state.postageDetail.region_id
      : "";
    if (state_id != undefined && state_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_CITIES",
        { state_id: state_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              cities: response.data,
            });
          }
        },
        true
      );
    }
  };

  getAllHomeRegions = () => {
    let country_id = this.state.homeDetail.country_id
      ? this.state.homeDetail.country_id
      : "";
    if (country_id != undefined && country_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_STATE",
        { country_id: country_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              regionsHome: response.data,
            });
          }
        },
        true
      );
    }
  };

  getAllHomeCities = () => {
    let state_id = this.state.homeDetail.region_id
      ? this.state.homeDetail.region_id
      : "";
    if (state_id != undefined && state_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_CITIES",
        { state_id: state_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              citiesHome: response.data,
            });
          }
        },
        true
      );
    }
  };

  handelCountryDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllRegions();
    });
  };

  handelRegionDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllCities();
    });
  };

  handelHomeCountryDetail = (event) => {
    const { homeDetail } = this.state;
    homeDetail[event.target.name] = event.target.value;
    this.setState(homeDetail, () => {
      this.getAllHomeRegions();
    });
  };

  handelHomeRegionDetail = (event) => {
    const { homeDetail } = this.state;
    homeDetail[event.target.name] = event.target.value;
    this.setState(homeDetail, () => {
      this.getAllHomeCities();
    });
  };

  render() {
    const {
      isLoading,
      showHomeAddressModal,
      showPostageAddressModal,
      countries,
      regions,
      cities,
      countriesHome,
      regionsHome,
      citiesHome,
    } = this.state;

    return (
      <>
        <div
          className="tab-pane allcom_rodtab active"
          id="subtab025"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter d-none d-md-block"
                  id="subtab_secondfixed"
                >
                  <div className="subcateleft_imgsec">
                    <img src="/images/account-settings-limg.png" alt="Image" />
                  </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                  <div className="formnotification_inner withbg_white border-radius p-4">
                    <p className="btnoff_onlable">Your Home Address</p>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="btnoff_onlable">
                        {this.state.isHomeDetail ? "Edit" : "Add"} Address
                      </p>
                      <button
                        className="btnx btn-secondaryx  font-size-14"
                        onClick={() =>
                          this.setState({
                            showHomeAddressModal: true,
                          })
                        }
                      >
                        {this.state.isHomeDetail ? "Edit" : "Add"}
                      </button>
                    </div>
                  </div>
                  <div className="formnotification_inner withbg_white border-radius mt-3 p-4">
                    <p className="btnoff_onlable">Your Shipping Address</p>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="btnoff_onlable">When making purchases, you can securely save your information for stripe 1-click checkout using your mobile number
                      </p>
                      {/* <button
                        className="btnx btn-secondaryx font-size-14"
                        onClick={() =>
                          this.setState({
                            showPostageAddressModal: true,
                          })
                        }
                      >
                        {this.state.isPostageDetail ? "Edit" : "Add"}
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showHomeAddressModal}
          ariaHideApp={false}
          onRequestClose={() => this.setState({ showHomeAddressModal: false })}
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button
            onClick={() => this.setState({ showHomeAddressModal: false })}
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="col-lg-12 listside_newperent">
            <form onSubmit={this.handleSubmitChangeHomeAddress}>
              <div className="formnotification_inner withbg_white border-radius">
                <div className="noti_enable">
                  <h3 className="titleof_tbasec">Update Address</h3>
                  <div className="box_perentrepet">
                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Street Address<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handleHomeDetails}
                            name="line1"
                            type="text"
                            defaultValue={
                              this.state?.homeDetail?.line1
                                ? this.state?.homeDetail.line1
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Street Address"
                          />
                        </div>
                      </div>

                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Street Address2<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handleHomeDetails}
                            name="line2"
                            type="text"
                            defaultValue={
                              this.state.homeDetail?.line2
                                ? this.state.homeDetail.line2
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Street Address 2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Country<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="country"
                            defaultValue={
                              this.state.homeDetail?.country === "GB"
                                ? "United Kingdom"
                                : this.state.homeDetail.country
                            }

                            onChange={this.handleHomeDetails}
                          />
                          {/* <option value="">Country</option>
                            {countriesHome &&
                              countriesHome.length > 0 &&
                              countriesHome.map(function (country) {
                                return (
                                  <option value={country.id} key={country._id}>
                                    {country.name}
                                  </option>
                                );
                              })}
                          </select> */}
                        </div>
                      </div>
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          City<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="city"
                            defaultValue={
                              this.state.homeDetail && this.state.homeDetail.city
                                ? this.state.homeDetail.city
                                : ""
                            }
                            onChange={this.handleHomeDetails}
                          />
                          {/* <option value="">City</option>
                            {citiesHome &&
                              citiesHome.length > 0 &&
                              citiesHome.map(function (city) {
                                return (
                                  <option value={city.id} key={city._id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                          </select> */}
                        </div>
                      </div>
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Region<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="region_id"
                            defaultValue={
                              this.state.homeDetail?.region_id
                                ? this.state.homeDetail.region_id
                                : ""
                            }
                            onChange={this.handleHomeDetails}
                          />
                          {/* <option value="">Region</option>
                            {regionsHome &&
                              regionsHome.length > 0 &&
                              regionsHome.map(function (region) {
                                return (
                                  <option value={region.id} key={region._id}>
                                    {region.name}
                                  </option>
                                );
                              })}
                          </select> */}
                        </div>
                      </div>
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Postcode<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handleHomeDetails}
                            name="postal_code"
                            type="text"
                            defaultValue={
                              this.state.homeDetail?.postal_code
                                ? this.state.homeDetail.postal_code
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Postcode"
                          />
                        </div>
                      </div>
                    </div>



                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Mobile Number<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={
                              this.handleHomeDetails
                            }
                            name="mobileNumber"
                            type="text"
                            defaultValue={(this.state.homeDetail?.mobileNumber) ? this.state.homeDetail.mobileNumber : ""}
                            className="social_red_fuild"
                            placeholder="Mobile Number"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="contdetail_group">
                      <div className="bottonsend text-center">
                        <button type="submit" className="btnx btn-secondaryx">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
        {/* <Modal
          isOpen={showPostageAddressModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showPostageAddressModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button
            onClick={() => this.setState({ showPostageAddressModal: false })}
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>
          <div className="col-lg-12 listside_newperent">
            <form onSubmit={this.handleSubmitChangeDeliveryAddress}>
              <div className="formnotification_inner withbg_white border-radius">
                <div className="noti_enable">
                  <h3 className="titleof_tbasec">Update Address</h3>
                  <div className="box_perentrepet">
                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          First Name<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handlePostageDetails}
                            name="firstName"
                            type="text"
                            defaultValue={
                              this.state?.firstName
                            }
                            className="social_red_fuild"
                            placeholder="First Name"
                          />
                        </div>
                      </div>

                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Last Name<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handlePostageDetails}
                            name="lastName"
                            type="text"
                            defaultValue={
                              this.state?.lastName

                            }
                            className="social_red_fuild"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Street Address<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handlePostageDetails}
                            name="line1"
                            type="text"
                            defaultValue={
                              this.state?.postageDetail?.line1
                                ? this.state?.postageDetail.line1
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Street Address"
                          />
                        </div>
                      </div>

                      <div className="contdetail_groups col-6">
                        <div className="contact_labels">
                          Street Address 2<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handlePostageDetails}
                            name="line2"
                            type="text"
                            defaultValue={
                              this.state.postageDetail?.line2
                                ? this.state.postageDetail.line2
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Street Address 2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Country<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="country"
                            defaultValue={
                              this.state.postageDetail?.country === "GB"
                                ? "United Kingdom"
                                : this.state.postageDetail.country
                            }
                            onChange={this.handelCountryDetail}
                          />
                        </div>
                      </div>
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Region<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="region_id"
                            defaultValue={
                              this.state.postageDetail?.region_id
                                ? this.state.postageDetail.region_id
                                : ""
                            }
                            onChange={this.handelRegionDetail}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          City<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            className="social_red_fuild form-control"
                            name="city"
                            defaultValue={
                              this.state.postageDetail?.city
                                ? this.state.postageDetail.city
                                : ""
                            }
                            onChange={this.handlePostageDetails}
                          />
                        </div>
                      </div>
                      <div className="contdetail_groups col-6">
                        <div className="contact_label">
                          Postcode<sup>*</sup>
                        </div>
                        <div className="cont_fuilds">
                          <input
                            onChange={this.handlePostageDetails}
                            name="postal_code"
                            type="text"
                            defaultValue={
                              this.state.postageDetail?.postal_code
                                ? this.state.postageDetail.postal_code
                                : ""
                            }
                            className="social_red_fuild"
                            placeholder="Postcode"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="contdetail_group">
                      <div className="bottonsend text-center">
                        <button type="submit" className="btnx btn-secondaryx">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal> */}
      </>
    );
  }
}

export default Postage;
